
export function obtenerFechaActualFormateada() {
    const fechaActual = new Date();

    // Obtenemos los componentes de la fecha
    const dia = agregarCeroAntes(fechaActual.getDate());
    const mes = agregarCeroAntes(fechaActual.getMonth() + 1);
    const anio = fechaActual.getFullYear();
    const horas = agregarCeroAntes(fechaActual.getHours());
    const minutos = agregarCeroAntes(fechaActual.getMinutes());
    const segundos = agregarCeroAntes(fechaActual.getSeconds());

    // Formateamos la fecha
    const fechaFormateada = `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos}`;

    return fechaFormateada;
}

function agregarCeroAntes(numero) {
    return numero < 10 ? `0${numero}` : numero;
}