import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

import './listadoProductos.css'
import Producto from "../../components/product/Producto";

// import addToCart from './images/add-to-cart.png'
// import search from './images/search.png'

const ListadoProductos = () => {
  const [productos, setProductos] = useState([]);
  // Obtiene el historial de navegación y la ubicación actual
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    function obtenerFechaFormateada() {
      const fechaActual = new Date();

      // Obtenemos los componentes de la fecha
      const dia = agregarCeroAntes(fechaActual.getDate());
      const mes = agregarCeroAntes(fechaActual.getMonth() + 1);
      const anio = fechaActual.getFullYear();
      const horas = agregarCeroAntes(fechaActual.getHours());
      const minutos = agregarCeroAntes(fechaActual.getMinutes());
      const segundos = agregarCeroAntes(fechaActual.getSeconds());

      // Formateamos la fecha
      const fechaFormateada = `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos}`;

      return fechaFormateada;
    }
    function agregarCeroAntes(numero) {
      return numero < 10 ? `0${numero}` : numero;
    }
    // Llamada al servicio POST cuando el componente se monta
    const fetchData = async () => {
      try {
        const response = await fetch('https://capacitatec.net/wp-admin/admin-ajax.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            action: 'getProductosGeneral',
            // Otros parámetros en el cuerpo según sea necesario
          }),
        });

        if (!response.ok) {
          throw new Error('Error al obtener productos');
        }

        // Manejar la respuesta aquí si es necesario
        const data = await response.json();
        console.log(data);
        setProductos(data);
      } catch (error) {
        console.error('Error de red:', error);
      }
    };

    fetchData();
    // Función para verificar la URL y el parámetro de consulta
    const verificarURL = async () => {
      const idUsuarioLogueado = localStorage.getItem('idUsuarioLogueado');
      // console.log(window.location.href)
      const urlActual = window.location.href;
      console.log(urlActual);
      const urlEnMinusculas = window.location.href.toLowerCase();
      console.log(urlEnMinusculas);
      const urlObjeto = new URL(urlEnMinusculas);
      const idClienteParam = urlObjeto.searchParams.get('idcliente');

      if ((!isNaN(idClienteParam) && idClienteParam > 0) || idUsuarioLogueado !== null) {
        if (!isNaN(idClienteParam) && idClienteParam > 0) {
          localStorage.setItem("idUsuarioLogueado", idClienteParam);
          try {
            // Aquí deberías realizar la lógica para obtener los detalles del producto según el ID
            // Puedes utilizar servicios, llamadas a API, etc.
            const response = await fetch('https://capacitatec.net/wp-admin/admin-ajax.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: new URLSearchParams({
                action: 'getDatosUsuario',
                filtro: idClienteParam
              }),
            });
            if (!response.ok) {
              throw new Error('Error al obtener productos');
            }
            const data = await response.json();
            localStorage.setItem("nombres", data[0].display_name);
            document.getElementById("nombre-usuario").textContent = data[0].display_name;
            if (idUsuarioLogueado === null) {
              const fechaFormateada = obtenerFechaFormateada();
              localStorage.setItem('fechaRegistro',fechaFormateada)
            } else {
              if (idClienteParam !== idUsuarioLogueado) {
                const fechaFormateada = obtenerFechaFormateada();
                localStorage.setItem('fechaRegistro',fechaFormateada)
              }
            }
          } catch (error) {
            console.error("Error al obtener detalles del producto:", error);
          }
        }
        document.getElementById("nombre-usuario").textContent = localStorage.getItem('nombres');
      } else {
        // Si el parámetro es incorrecto, mostrar SweetAlert y redirigir al hacer clic en "Entendido"
        const alerta = Swal.fire({
          icon: 'error',
          title: 'Acceso denegado',
          text: 'Por favor, debe loguearse e ingresar desde la opción Tienda de la página web.',
          confirmButtonText: 'Entendido',
          allowOutsideClick: false,
        });

        const redirigirAPaginaPrincipal = () => {
          window.location.href = 'https://capacitatec.net/';
        };

        const intervalo = setInterval(() => {
          if (!Swal.getContainer()) {
            redirigirAPaginaPrincipal();
            clearInterval(intervalo);
          }
        }, 500);

        alerta.then((result) => {
          if (result.isConfirmed) {
            redirigirAPaginaPrincipal();
          }
        });
      }

      const verificarHoraLimite = () => {
        let fechaIngreso = localStorage.getItem("fechaRegistro");
        if (fechaIngreso) {
          const [diaMesAnio, horaMinSeg] = fechaIngreso.split(' ');
          const [dia, mes, anio] = diaMesAnio.split('/');
          const [hora, min, seg] = horaMinSeg.split(':');
          if (!isNaN(idClienteParam) || idClienteParam==='null' || idClienteParam === "0" || idClienteParam === "") {
            const horaMas1 = (parseInt(hora) + 1);
            const nuevaFechaMas1h = new Date(`${mes}/${dia}/${anio} ${horaMas1}:${min}:${seg}`);
            const horaActual = new Date();
            // const horaActual = new Date('02/13/2024 19:43:53');
            //localStorage.setItem('horaActual',horaActual)
            //localStorage.setItem('nuevaFechaMas1h',nuevaFechaMas1h)
            if (horaActual > nuevaFechaMas1h) {
              const alerta = Swal.fire({
                icon: 'error',
                title: '¡Alerta! La hora límite ha pasado',
                confirmButtonText: 'Entendido',
                allowOutsideClick: false,
              });
              const redirigirAPaginaPrincipal = () => {
                window.location.href = 'https://capacitatec.net/';
              };
              const intervalo = setInterval(() => {
                if (!Swal.getContainer()) {//Verifica si el contenedor del sweetAlert no existe
                  redirigirAPaginaPrincipal();
                  clearInterval(intervalo);//detiene la ejecución repetida de lo que se configuró dentro del setInterval
                }
              }, 500);
              alerta.then((result) => {
                if (result.isConfirmed) {
                  redirigirAPaginaPrincipal();
                }
              });
            }
          }
        }
      };
      verificarHoraLimite();
    };

    // Llama a la función al cargar el componente o cuando cambia la ubicación
    verificarURL();
  }, [location.search, navigate]);

  return (
    <div className="container listadoProductos">
      {productos.map((producto) => (
        <Producto
          key={producto.IdProduct}  // Asegúrate de tener una propiedad 'key' única para cada producto
          nomProduct={producto.ProductName}
          number={producto.IdProduct}
          precio={producto.ProductPrice}
          img={producto.ProductImage}
        />
      ))}
    </div>
  );
};

export default ListadoProductos;
